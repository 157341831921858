/* body {
  margin: 0;
  font-family: "Helvetica";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@300;400;500;600;700&display=swap");

body {
  font-family: "IBM Plex Sans Thai", "Helvetica";
}

span {
  font-family: "IBM Plex Sans Thai", "Helvetica";
}

a {
  font-family: "IBM Plex Sans Thai", "Helvetica";
}

label {
  font-family: Sarabun;
}

code {
  font-family: "Helvetica";
}

svg {
  vertical-align: baseline;
}

.ant-menu-item-selected {
  background-color: #1b4586 !important;
  color: #fff !important;
  margin: 0 auto !important;
  border-radius: 8px;
  display: flex !important;
  align-items: center !important;
}

.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: #1b4586 !important;

  :hover {
    color: white !important;
  }
}

.ant-menu-inline .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-vertical .ant-menu-item:after {
  border-right: 0px !important;
}

.rdrDefinedRangesWrapper,
.rdrDateDisplayWrapper {
  display: none;
}

.rdrCalendarWrapper {
  font-size: 14px !important;
}

.rdrDateRangePickerWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rdrInRange {
  background-color: #82bbff99 !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #cbcdcc;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
  background: #cbcdcc;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1677ff !important;
  border-color: #1677ff !important;
}

/* custom scrollbar */
.ant-table-body {
  scrollbar-width: auto;
  scrollbar-color: auto;
}

.ant-table-body::-webkit-scrollbar {
  width: 4px;
}

.ant-table-body::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #cbcdcc;
}

.pointer {
  cursor: pointer;
}